



























































































































// Core
import { Component, Watch, Mixins, PropSync } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Types
import { TeamActions } from '@/store/team/types'

// Store
import { documentsNamespace } from '@store/documents'

// Interfaces
import { ElForm } from 'element-ui/types/form'
import { IDocumentTypes } from '@store/documents/Interface'
import { Invite } from '@/store/team/interfaces'

// Modules
const NSTeam = namespace('teamModule')

@Component({
  name: 'DialogInviteSpecialist',
})
export default class DialogInviteSpecialistComponent extends Mixins(GetStoreMixin) {
  @PropSync('visible', Boolean) private _visible!: boolean

  @documentsNamespace.State('documentTypes')
  private documentType!: IDocumentTypes

  @NSTeam.Action(TeamActions.A_INVITE_SPECIALIST)
  private inviteSpecialist!: (invite: Invite) => Promise<void>

  private form = {
    projectId: 0,
    title: '',
    documentTypeId: null,
    description: '',
    completionDate: '',
    email: '',
  }

  private rules: any = {
    title: [{ required: true, message: 'Обязательное поле', trigger: ['blur', 'change'] }],
    documentTypeId: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
    description: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
    completionDate: [{ required: true, message: 'Обязательное поле', trigger: 'change' }],
    email: [
      { required: true, message: 'Обязательное поле', trigger: 'blur' },
      { type: 'email', message: 'Неправильный e-mail', trigger: ['blur', 'change'] },
    ],
  }

  private created() {
    this.form.projectId = this.selectedProject
  }

  private handleInviteSpecialist() {
    const form = this.$refs.form as ElForm

    form.validate(isValid => {
      if (isValid) {
        this.inviteSpecialist(this.form)
          .then(() => {
            this.$notify({
              title: 'Выполнено',
              message: 'Действие успешно выполнено',
              type: 'success',
            })

            this._visible = false

            form.resetFields()
          })
          .catch(error => {
            this.$notify.error({
              title: 'Ошибка',
              message: error.response.data.message,
            })
          })
      }
    })
  }
}
